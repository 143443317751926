.service-container {
  position: relative;
}

.text-container {
  display: flex;
  justify-content: center;
}

.service-title {
  color: var(--dark-blue);
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.arrowImg {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
  padding-bottom: 4rem;
}

.arrowImg img {
  max-width: 100%;
  height: auto;
  cursor: pointer;
}

.taptopay-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  /* padding-bottom: 6rem; */
}

.txt-col {
  display: grid;
  grid-template-rows: repeat(3, auto);
  gap: 15px;
}

.txt-bx {
  border-radius: 16px;
  background-color: #ffffff;
  padding: 1.7rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  color: var(--dark-blue);
  border: 1px solid #99a8fc;
}
.txt-bx h3 {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  padding-bottom: 2rem;
}
.txt-bx p {
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
}

.img-col img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.table-title {
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  text-transform: uppercase;
  color: var(--dark-blue);
  margin-top: 100px;
}

@media (min-width: 768px) {
  .table-title {
    margin-top: 200px;
  }

  .service-title {
    font-size: 65px;
  }
}

@media only screen and (max-width: 1024px) {
  .txt-bx {
    padding: 1.2rem;
  }
  .txt-bx h3 {
    padding-bottom: 0.7rem;
  }
}

@media only screen and (max-width: 768px) {
  .txt-bx {
    padding: 2rem;
  }
  .txt-bx h3 {
    padding-bottom: 1rem;
  }
  .taptopay-container {
    grid-template-columns: 1fr;
  }

  .txt-col,
  .img-col {
    width: 100%;
  }

  .table-title {
    font-size: 32px;
    line-height: 39px;
  }
}

/*--------------------- old CSS ---------------------- */
.corporate-header {
  color: #03045e;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.btn-teriff-block {
  justify-content: center;
  display: flex;
  align-items: center;
  padding-top: 21px;
  margin-bottom: 125px;
}

/* SERVICE TABLE ----------------------------------------------- */
.static-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
  border-radius: 10px;
  overflow: auto;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 6rem;
  border-bottom: 0.5px solid var(--light-blue);
}
.table-row {
  width: 100%;
  display: table;
  table-layout: fixed;
  border-bottom: 0.5px solid var(--light-blue);
  background: #fff;
}

/* .table-header {
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  color: var(--Black, #2a2e37);
  border-bottom: 0.5px solid var(--Main-Blue, #03045e);
  background: #f6f9ff;
} */

.table-cell {
  display: table-cell;
  text-align: left;
  padding: 11px 18px;
}

.cellText {
  word-wrap: break-word;
}

/* .table-cell:first-child {
  border-right: 1px solid var(--Main-Blue, #03045e);
} */

.table-cell:nth-child(2) {
  text-align: right;
  margin-right: 10px;
}
.table-cell:nth-child(2) {
  text-align: right;
  margin-right: 20px;
}
.table-row:last-child {
  border-bottom: none;
}

.cellText {
  color: var(--dark-blue);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px;
}
.lightText {
  color: #99a8fc;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(100deg, #7484d9, #2b42aa);
  border-radius: 16px;
  padding: 16px;
  color: #fff;
  margin-bottom: 1rem;
  margin-top: 48px;
}

/* for md devices */
@media (min-width: 768px) {
  .heading-container {
    margin-top: 80px;
  }
}

.left-heading,
.right-heading {
  margin: 0;
  font-size: 24px;
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  .heading-container {
    flex-direction: row;
    gap: 10px;
  }
  .left-heading,
  .right-heading {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
  }
  .cellText {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding: 10px;
  }
  .lightText {
    font-size: 14px;
    font-weight: 400;
  }
}

.сurrency-wrapper {
  background: url('/src/assets/back.svg'), url('/src/assets/dashborad-bg-logo.svg') #f6f9ff no-repeat no-repeat center center;
  background-size: cover, calc(40%);
  padding-block: 5rem;
  min-height: 100vh;
}

.currency-tabs-box {
  display: flex;
  align-items: start;
  justify-content: start;
  margin-bottom: 2rem;
  gap: 1rem;
  border: 1px solid hsla(180, 3%, 94%, 1);
  background-color: white;
  width: max-content;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.currency-tab {
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: #ffffff;
  color: gray;
  font-size: var(--normal-font-size);
  font-weight: 600;
}

.currency-tab.active {
  background-color: hsla(239, 94%, 19%, 0.05);
  color: black;
}

.currency-title-box {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.5rem;
  gap: 1rem;
  height: 70px;
}

@media only screen and (min-width: 768px) {
  .ccurrency-account-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1.5rem;
    column-gap: 2rem;
  }

  .currency-title-box {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding-right: 2rem;
  }
}

.logo_block {
  position: relative;
  max-width: 613px;
  max-height: 383px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.logo_text {
  background-image: url('../../../assets/logo-text.svg');
  background-size: cover;
  width: 613px;
  height: 118px;
}

.quest_block {
  position: absolute;
  top: 40px;
  right: 60px;
}

.title {
  color: #000;
  text-align: center;
  font-family: Mulish, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

.input_label {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #979797;
  margin-bottom: 8px;
}

.my_input {
  width: 420px;
  height: 48px;
  background: #ffffff;
  border-radius: 6px;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2a2e37;
  margin-top: 24px;
}

.span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  color: #979797;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.span:hover {
  color: #ef233c;
  cursor: pointer;
}

.error {
  font-family: 'Mulish', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-top: 4px;
}

.btn_block {
  display: flex;
  justify-content: center;
  margin-top: 38px;
}

.login_btn {
  display: flex;
  width: 200px;
  height: 50px;
  justify-content: center;
  align-items: center;
  gap: 32px;
  border-radius: 95px;
  border: 3px solid rgba(255, 255, 255, 0.12);
  background: #03045e;
  cursor: pointer;
  font-family: 'Mulish', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  transition: 0.3s all ease;
}

/* .my_btn:hover {
    background: #2A2CD9;
    transition: .3s all ease;
} */

.invalid-input-error {
  color: red;
  font-size: var(--smaller-font-size);
  margin-bottom: 0.5rem;
}

.register_link a {
  color: #979797;
  font-size: 14px;
  font-weight: 400;
  margin-left: 4px;
  text-decoration: none;
}

.error_block {
  height: 52px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
  background: #ffe8ea;
  border-radius: 8px;
  color: #ff6471;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  .login_wrapper_content_left {
    position: relative;
    height: 100vh;
    width: 100%;
    background-image: url('../../../assets/login-background.svg');
    background-size: 10%;
    display: flex;
    justify-content: flex-start;
    transition: 0.3s all ease;
    flex-direction: column-reverse;
  }

  .logo_block {
    position: relative;
    max-width: 250px;
    max-height: 383px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .my_input {
    font-family: 'Mulish', serif;
    width: 100%;
    height: 40px;
    background: #ffffff;
    padding: 14px 12px;
    box-sizing: border-box;
    outline: none;
    border: none;
    margin-top: 20px;
  }

  .logo_block {
    position: absolute;
    top: 20px;
    left: 16px;
  }
}

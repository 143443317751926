.footer {
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  background-color: white;
  padding: 24px 0;
  box-shadow: 0 -4px 10px 0 #0000000d;
}

.footer_wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  /* justify-content: space-between;
  align-items: start; */
}

.footer_wrapper .footer_logo {
  width: 78px;
  margin-left: 20px;
}

.footer_column .title {
  font-size: 20px;
  color: var(--dark-blue);
  font-weight: 500;
}

.footer_column .rows {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footer_column .rows .link {
  font-size: 16px;
  color: var(--dark-blue);
  font-weight: 400;
  text-decoration: none;
  white-space: nowrap;
  transition: all 0.3s;
}

.footer_column .rows .link:hover {
  color: var(--main-red);
}

.footer_column .item {
  color: var(--dark-blue);
  font-size: 16px;
}

.footer_column .footer_flex {
  display: flex;
  gap: 10px;
  align-items: start;
}

.footer_flex.align_center {
  align-items: center;
}

.footer_flex.social_icons {
  gap: 12px;
}

.footer_flex p {
  font-size: 16px;
  color: var(--dark-blue);
}

.footer_flex p.light {
  font-size: 16px;
  color: var(--light-blue);
}

.footer_flex p.copy_right {
  font-size: 14px;
  color: var(--light-blue);
}

.footer_flex p a {
  font-size: inherit;
  color: var(--dark-blue);
  text-decoration: none;
}

.footer_flex p.link:hover a {
  color: var(--main-red);
}

.footer_flex svg path,
.footer_flex svg circle {
  transition: all 0.3s;
}

.footer_flex svg:hover path {
  fill: var(--main-red);
}

.footer_flex svg:hover circle {
  stroke: var(--main-red);
}

/* for md devices */
@media (min-width: 768px) {
  .footer {
    padding: 48px 0;
  }

  .footer_wrapper {
    grid-template-columns: 1fr 1fr;
    gap: 70px;
  }

  .footer_wrapper .footer_logo {
    width: 100px;
  }

  .footer_column .rows {
    gap: 12px;
  }

  .footer_column .title {
    font-size: 24px;
    font-weight: 400;
  }

  .footer_column .rows .link {
    font-size: 14px;
  }

  .footer_column .item {
    font-size: 14px;
  }

  .footer_column.license {
    max-width: 250px;
  }

  .footer_column .footer_flex {
    gap: 20px;
  }

  .footer_flex p {
    font-size: 14px;
  }

  .footer_flex p.copy_right {
    font-size: 11px;
  }
}
/* for lg devices */
@media (min-width: 1280px) {
  .footer_wrapper {
    grid-template-columns: repeat(5, 1fr);
  }
}

svg.social_icon {
  width: 30px;
  height: 30px;
}

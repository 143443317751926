.bank-transfer-container{
  padding-block: 1rem 3rem;
}

.bank-transfer-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
  gap: 1rem;
  
}

.tab-button {
  padding: 10px 15px;
  border: 1px solid #03045E;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  width: 100%;
  max-width: 100%;
  background-color: #ffffff;
  color: #03045E;
  font-size: var(--normal-font-size);
}

.tab-button.active {
  background-color: #03045E;
  color: #ffffff;
}

.transfer-title {
  font-size: 12px;
}

.transfer-form {
  padding-inline: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.transfer-form-box {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  max-width: 550px;
  width: 100%;
  margin-bottom: 1.3rem;
}

.transfer-form-group {
  display: flex;
  flex-direction: column;
  column-gap: 1.5rem;
  width: 100%;
  max-width: 550px;  
}

.transfer-form-group .transfer-form-box {
  width: 100%;
}

.transfer-form-label {
  color: var(--gray-color);
  font-size: var(--normal-font-size);
  vertical-align: center;
}

.transfer-form-icon {
  color: red;
  font-size: calc(var(--normal-font-size) + .3rem);
  vertical-align: center;
  font-weight: 700;
}

.transfer-form-input {
  outline: none;
  padding: 1rem;
  height: max-content;
  font-size: var(--normal-font-size);
  border-radius: 8px;
  background: var(--white-color);
  border: 1px solid var(--white-color);
}

.transfer-form-input:focus {
  border: 1px solid var(--gray-color);
}

.transfer-form-subtitle {
  width: 100%;
  max-width: 550px;
  font-size: var(--h4-font-size);
  color: #03045E;
  margin-bottom: .6rem;
}

.transfer-form-textarea {
  width: 100%;
  padding: 17px 16px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  font-size: var(--normal-font-size);
  border: 1px solid var(--light-gray );
  resize: none;
}

.transfer-form-textarea::placeholder {
  color: var(--gray-color);
}

.transfer-form-bottom {
  color: var(--Black, #2A2E37);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
  max-width: 550px;
}

@media (min-width: 900px) {
  .bank-transfer-button {
    width: 60%;
  }

  .transfer-form-bottom {
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  .bank-transfer-button {
    flex-direction: row;
    width: 60%;
  }
  
  .transfer-form-group {
    flex-direction: row;
  }
}






@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --header-height: 0px;

  /* Default font size */
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.2rem;
  --h4-font-size: 1rem;
  --h5-font-size: 0.8rem;
  --big-font-size: 3rem;
  --smaller-font-size: 0.6rem;

  --primary-big-font-size: 7rem;
  --secondary-big-font-size: 6rem;
  --tertiary-big-font-size: 3rem;
  --quaternary-big-font-size: 2rem;

  --body-font-size: 18px;

  /* font size */
  --primary-font-size: 1rem;
  --secondary-font-size: 1.3rem;
  --tertiary-font-size: 1rem;
  --quaternary-font-size: 1rem;

  --normal-font-size: 0.938rem;

  /* COLORS */
  --white-color: #fff;
  --gray-color-one: #979797;
  --blue-color: #2046a1;
  --dark-blue-color: #03136a;
  --main-blue: #5d6cc2;
  --dark-blue: #03136a;
  --light-blue: #99a8fc;
  --main-red: #d90429;

  /* blue-gradient-backgroung */
  --gradient-background: linear-gradient(280.49deg, #2b42aa 8.5%, #7484d9 100%);
  /* line height */
  --primary-line-height: 1.6rem;

  /* PADDING */
  --padding-right: 7rem;
}

/* For large screens */
@media (min-width: 768px) {
  :root {
    --h1-font-size: 2.3rem;
    --h2-font-size: 2rem;
    --h4-font-size: 1.3rem;
    --h5-font-size: 1rem;

    --big-font-size: 4rem;
    --smaller-font-size: 0.8rem;

    /* big font size */
    --tertiary-big-font-size: 6rem;
    --quaternary-big-font-size: 3rem;

    /* line height */
    --primary-line-height: 1.8rem;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: var(--body-font-size);
  font-family: 'Montserrat', serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

.section {
  padding-block: 1.5rem;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 8px;
  padding-right: 8px;
}
@media (min-width: 640px) {
  .container {
    max-width: 624px;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 752px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1008px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1232px;
  }
}
@media (min-width: 1440px) {
  .container {
    max-width: 1392px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1488px;
  }
}

.section-title {
  color: #03045e;
  font-size: var(--h1-font-size);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.section-title-center {
  color: #03045e;
  font-size: var(--h1-font-size);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 24px;
  text-align: center;
}

.section-subtitle {
  color: #03045e;
  font-size: var(--h2-font-size);
  font-weight: 650;
  text-align: start;
}

@media (min-width: 768px) {
  .section-title {
    font-size: var(--h2-font-size);
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 1200px) {
  .section {
    padding-block: 2.5rem;
  }

  .section-title {
    font-size: var(--h1-font-size);
  }
}

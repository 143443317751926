.open-account-section {
  margin-top: 100px;
}

.corporate-container {
  margin-top: 100px;
}

.corporate-subtitle {
  color: var(--dark-blue);
  font-size: var(--quaternary-big-font-size);
  text-transform: uppercase;
  font-weight: 700;
}
.corporate-header {
  display: flex;
  gap: 10px;
  align-items: stretch;
  padding-bottom: 2rem;
}

.corporate-header-image,
.corporate-header-content {
  flex: 1;
}

.corporate-header-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

.corporate-header-content {
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 4rem 2rem;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.corporate-header-content p {
  font-size: 24px;
  padding-bottom: 1rem;
  color: var(--dark-blue);
}

.myBtnWrapper {
  margin-top: auto;
  padding-top: 2rem;
}
.opening-account {
  display: flex;
  gap: 10px;
  margin-top: 48px;
  flex-direction: column;
}

.opening-account-left {
  background: linear-gradient(100deg, #7484d9, #2b42aa);
  color: #ffffff;
  padding: 24px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.opening-account-left p {
  margin-bottom: 30px;
  font-size: 18px;
}

.opening-account-right {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.account-row {
  flex: 1;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #99a8fc;
  padding: 24px;
  border-radius: 16px;
}

.account-row-content {
  display: flex;
  flex: 1;
  gap: 24px;
}

.icon img {
  width: 50px;
  height: 50px;
}

.text {
  flex: 1;
  color: var(--dark-blue);
}
.text h3 {
  font-size: 20px;
  padding-bottom: 24px;
  font-weight: 500;
}

.text p {
  font-weight: 400;
  font-size: 16px;
}

.doc-wraper {
  padding-bottom: 4rem;
  margin-top: 48px;
}

.open-account-docs {
  margin-top: 100px;
}

.document-title h3 {
  background: var(--gradient-background);
  color: #ffffff;
  border-radius: 16px;
  padding: 24px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 8px;
}

.white-box {
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  border-bottom: 0.5px solid var(--light-blue);
}

.white-box p {
  font-size: 18px;
  font-weight: 400;
  padding: 12px 24px;
  line-height: 21px;
  color: var(--dark-blue);
}

.white-box hr {
  border: 0;
  border-top: 0.5px solid var(--light-blue);
  margin: 0;
}
.doc-light {
  color: var(--light-blue);
}

@media only screen and (max-width: 768px) {
  .corporate-title {
    font-size: var(--h2-font-size);
  }
  .corporate-header {
    flex-direction: column;
  }
  .corporate-header-content {
    padding: 2rem 1rem;
  }
  .corporate-subtitle {
    font-size: 32px;
    font-weight: 700;
  }
  .opening-account {
    flex-direction: column;
  }
  .account-row-content {
    flex-direction: column;
  }
  .icon {
    flex: unset;
    margin-bottom: 8px;
  }

  .document-title {
    font-size: 16px;
  }
  .white-box p {
    font-size: 14px;
  }
}
@media (max-width: 1024px) {
  .corporate-header-content p {
    font-size: 16px;
    padding-bottom: 0.8rem;
  }
}
@media (min-width: 768px) {
  .corporate-container {
    margin-top: 200px;
  }

  .opening-account {
    margin-top: 80px;
    gap: 24px;
    flex-direction: row;
  }

  .opening-account-right {
    gap: 16px;
    width: calc(100% - 12px);
  }

  .opening-account-left {
    padding: 48px;
    min-height: 460px;
    width: calc(50% - 12px);
  }

  .opening-account-left p {
    font-size: 24px;
  }

  .opening-account-right {
    width: calc(50% - 12px);
  }

  .open-account-docs {
    margin-top: 200px;
  }

  .doc-wraper {
    margin-top: 90px;
  }

  .docs-title h3 {
    margin-bottom: 8px;
  }

  .icon img {
    width: 100%;
    height: 100%;
  }

  .text h3 {
    font-weight: 400;
    font-size: 24px;
    padding-bottom: 24px;
  }

  .text p {
    font-size: 18px;
  }

  .open-account-section {
    margin-top: 200px;
  }
}

/* -----------------Old Css---------------- */

.corporate-content-container {
  padding-top: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.5rem;
}

.corporate-content-description {
  color: #2a2e37;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
}

.corporate-img-container {
  position: relative;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.corporate-content-img {
  width: 100%;
  max-width: 500px;
}

.corporate-cards-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 100px;
}

.corporate-cards {
  display: grid;
  grid-template-columns: 1fr;
}

.client-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.25), -4px -4px 15px 0px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  border: transparent;
  padding: 1rem;
  box-sizing: border-box;
  justify-content: center;
  margin-top: 60px;
  background: white;
}

.client-title {
  color: #03045e;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 24px;
}

.card-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.card-description {
  color: #2a2e37;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: auto;
  align-self: baseline;
}

/* CORPORATE ICONS ------------------------------ */
.corporate-icons-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  row-gap: 3rem;
  opacity: 0.5;
  padding-block: 5rem;
}

.corporate-icon {
  width: 90px;
}

/* CORPORATE LIST ------------------------------ */
.corporate-list-container {
  flex-shrink: 0;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  padding: 2.5rem 2rem;
}

.corporate-list li {
  list-style-type: none;
  border-bottom: 1px solid #ccc;
  color: #2a2e37;
  font-size: 16px;
  font-weight: 600;
  padding-block: 1rem;
}

.muted {
  color: #979797;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* FOR LARGE DEVICES */
@media screen and (min-width: 800px) {
  .corporate-content-container {
    padding-top: 2rem;
    grid-template-columns: 1fr 1fr;
  }

  .corporate-img-container {
    align-self: center;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

@media (min-width: 900px) {
  .corporate-icons-container {
    padding-block: 7rem;
  }
  .corporate-cards {
    grid-template-columns: 1fr 1fr;
    gap: 2.5rem;
  }

  .client-card {
    padding: 3rem;
  }

  .corporate-icon {
    width: 150px;
  }
}

@media (min-width: 1100px) {
  .corporate-cards-text {
    justify-content: start;
    align-items: start;
    text-align: left;
  }

  .corporate-img {
    width: initial;
  }
}

@media only screen and (max-width: 1024px) {
  .client-card {
    width: 100%;
    margin-right: 0;
  }
}

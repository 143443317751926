.auth-wrapper {
  position: relative;
  height: 100vh;
  width: 100%;
  background-image: url('../../assets/login-background.svg');
  background-size: cover;
  background-position: center;
  transition: 0.3s all ease;
}

.auth-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-logo-img {
  width: 160px;
  height: 160px;
}

.auth-question {
  margin-block: 1rem 1.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
}

.auth-question-link {
  text-decoration: none;
  color: #979797;
  font-size: 15px;
  cursor: pointer;
}

.auth-question-link:hover {
  color: #ef233c;
}

@media only screen and (min-width: 768px) {
  .auth-logo-img {
    width: 300px;
    height: 300px;
  }
}

.logo_block {
  position: relative;
  max-width: 613px;
  max-height: 383px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.logo_text {
  background-image: url('../../assets/logo-text.svg');
  background-size: cover;
  width: 613px;
  height: 118px;
}

.quest_block {
  position: absolute;
  top: 40px;
  right: 60px;
}

.sign_in_content {
  width: 430px;
}

.title {
  color: #000;
  text-align: center;
  font-family: Mulish, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 24px;
}

.input_label {
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #979797;
  margin-bottom: 8px;
}

.my_input {
  width: 420px;
  height: 48px;
  background: #ffffff;
  border-radius: 6px;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2a2e37;
  margin-top: 24px;
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  color: #979797;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 24px;
}

.error {
  font-family: 'Mulish', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-top: 4px;
}

.btn_block {
  display: flex;
  justify-content: center;
  margin-top: 38px;
}

/* .my_btn:hover {
    background: #2A2CD9;
    transition: .3s all ease;
} */

.register_link {
  color: #979797;
  font-size: 14px;
  font-weight: 400;
  margin-left: 4px;
}

.register_link:hover {
  color: #ef233c;
  cursor: pointer;
}

.register_link a {
  color: #979797;
  font-size: 14px;
  font-weight: 400;
  margin-left: 4px;
  text-decoration: none;
}

.error_block {
  height: 52px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
  background: #ffe8ea;
  border-radius: 8px;
  color: #ff6471;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.arrow_back {
  width: 24px;
  height: 24px;
  padding-top: 24px;
  padding-left: 16px;
  /*  left: 745px;
    top:24px;*/
  position: absolute;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

@media only screen and (max-width: 768px) {
  .logo_block {
    position: relative;
    max-width: 250px;
    max-height: 383px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .sign_in_content {
    width: 100%;
  }

  .my_input {
    font-family: 'Mulish', serif;
    width: 100%;
    height: 40px;
    background: #ffffff;
    padding: 14px 12px;
    box-sizing: border-box;
    outline: none;
    border: none;
    margin-top: 20px;
  }

  .logo_block {
    position: absolute;
    top: 20px;
    left: 16px;
  }
}

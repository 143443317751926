.my-dropdown-container {
    position: relative;
    width: 100%;
}

.my-dropdown-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: var(--white-color);
    padding: 1rem;
    font-size: var(--normal-font-size);
    border-radius: 8px;
    border: 1px solid var(--light-gray);
    height: 100%;
    width: 100%;
    transition: border 0.3s;
}

.my-dropdown-header:hover {
    border: 1px solid var(--gray-color);
}


.my-dropdown-input {
    background: transparent;
    outline: none;
    border:none;
    cursor: pointer;
    margin: 0;
}

.my-dropdown-icons{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    position: relative;

}

.my-dropdown-icon {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: red;
    user-select: none;
}

.my-dropdown-input:focus {
    border: none;
    outline: none;
}

.my-dropdown-container .error {
    border: 1px solid #FF6471;
}

.error_message {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FF6471;
    bottom: -20px;
    position: absolute;
}


.mySelect {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    justify-content: flex-end;
}

.my-dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-top: 2px;
}

.my-dropdown-list-item {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.my-dropdown-list-item:hover {
    background-color: #f0f0f0;
}

.my-dropdown-list-item.selected {
    background-color: #03045E;
    color: var(--white-color);
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
    margin-top: 25px;
    padding-top: 25px;
    height: 30px;
    background: #EBEBEB;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
    margin-left: -30px;
}
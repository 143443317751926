.my_btn {
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #D90429;
    transition: .3s all ease;
    cursor: pointer;
    width: max-content;
    padding-inline: 1rem;
    background-color: transparent;
    border-radius: 95px;
    border: 2px solid #D90429;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
}

.my_btn:hover {
    transition: .3s all ease;
}

.primary {
    border: 2px solid #D90429;
    color: #D90429;
}

.primary:hover {
    background-color: #D90429;
    color: #FFFFFF;
}

.secondary {
    border: 2px solid #FFFFFF;
    color: #FFFFFF;
}

.secondary:hover {
    background-color: #FFFFFF;
    color: #D90429;
}

.secondary:hover .img {
    filter: brightness(0) saturate(100%) invert(22%) sepia(94%) saturate(6158%) hue-rotate(345deg) brightness(94%) contrast(85%);
}

/* Primary button hover: image icon turns white */
.primary:hover .img {
    filter: brightness(0) invert(1); /* White color */
}

.img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    filter: none;
}

.medium {
    height: 44px;
}

.large {
    height: 50px;
    width: 200px;
    padding-inline: 1rem;
}

.small {
    height: fit-content;
}

.disabled,
.disabled:hover {
    background-color: #979797;
    transition: background-color .3ms ease-in;
}

.my_btn.confirm {
    background-color: #FFBB54;
}

.my_btn.confirm:hover {
    background-color: #FEC979;
}

.my_btn.error {
    background-color: #FF6471;
}

.my_btn.error:hover {
    background-color: #FF8A94;
}

@media only screen and (max-width: 768px) {
    .my_btn {
        width: 100% !important;
    }
}

.contact_page {
  margin-top: 32px;
}

.contact_us {
  padding-block: 100px;
}

/* for md devices */
@media (min-width: 768px) {
  .contact_us {
    padding-block: 200px;
  }
}

.side-menu-container {
    z-index: 1001;
    height: 100%;
    position: relative;
}

.side-menu {
    width: 230px;
    height: 100vh;
    position: fixed;
    top: 0;
    padding: 10px 20px;
    border-radius: 0px 16px 16px 0px;
    display: none;
    box-shadow: 4px 0px 4px 0px #00000040;
    background-color: #FFFFFF;
    transition: opacity 0.3s ease;
    z-index: 1001;
    justify-content: space-between;
    flex-direction: column;
}

.show-side-menu {
    display: flex;
    z-index: 1001;

}

.hide-side-menu{
    display: none;
}

.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding-block: 3rem .5rem;
}

.profile-details {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-picture {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.user-details {
    display: flex;
    align-items: center;
}

.user-name {
    margin-bottom: 5px;
    color: #03045E;
}

.verified-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: .5rem;
}

.verified-container::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #03045E;
    margin-top: 1px;
}

.verified-icon {
    width: 13px;
    height: 13px;
    margin-block: 0;
}

.verified-text {
    font-size: 11px;
    color:  #03045E;
}

.sidebar-list,
.sidebar-bottom-content {
    display: flex;
    flex-direction: column;
    row-gap: 1.3rem;
    margin-block: 1rem;
}

/* sidebar-link ---- */
.sidebar-link {
    cursor: pointer;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: .5rem;
    color:  #03045E;
    font-size: var(--normal-font-size);
}

.sidebar-icon {
    width: 22px;
    height: 22px;
    filter: brightness(100%);
    transition: filter 0.90s linear;
}

.sidebar-link:hover .sidebar-icon {
    filter: invert(25%) sepia(83%) saturate(6252%) hue-rotate(340deg) brightness(88%) contrast(113%);
}

.sidebar-link-name {
    font-size: calc(var(--normal-font-size) - .1rem);
}

.sidebar-link.sidebar-active-link .sidebar-link-name {
    font-weight: 700;
}

.sidebar-link.sidebar-active-link .sidebar-icon {
    filter: invert(25%) sepia(83%) saturate(6252%) hue-rotate(340deg) brightness(88%) contrast(113%);
}

.toggle-button {
    cursor: pointer;
    position: fixed;
    top: 15px;
    left: 15px;
    background-color: transparent;
    padding: 7px 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1002;
}

@media (min-width: 768px) {
    .side-menu {
        width: 260px;
        padding: 15px 30px;
    }

    .verified-text {
        font-size: 12px;
    }

    .sidebar-list,
    .sidebar-bottom-content {
        row-gap: 1.7rem;
    }

    .sidebar-link-name {
        font-size: var(--normal-font-size);
    }
}
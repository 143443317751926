
 /* Keyframes for the fade-in effect */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Class to trigger the fade-in animation */
.fade-in {
  animation: fadeIn 1s ease-out;
}

/* Apply fade-in effect to the img-col and txt-col containers */
.slider-container .img-col, .slider-container .txt-col {
  opacity: 0;
  animation: none; /* Disable animation initially */
}

.slider-container.fade-in .img-col, .slider-container.fade-in .txt-col {
  opacity: 1;
  animation: fadeIn 1s ease-out;
}

 
.taptopay-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
  .txt-col {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .txt-bx {
    flex: 1;
    border-radius: 16px;
    background-color: #ffffff;
    padding: 2rem 3rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    color: var(--dark-blue-color);
    border: 1px solid #99a8fc;
    transition: all 0.3s;
    cursor: pointer;
  }
  .txt-bx:hover {
    color: white;
    background: var(--gradient-background);
  }
  .txt-bx h3 {
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    padding-bottom: 2rem;
  }
  .txt-bx p {
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
  }
  .card_link{
    text-decoration: none;
  }
  
  .img-col img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
}

@media (min-width: 768px) {
  .taptopay-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 1024px) {
  .txt-bx {
    padding: 1.2rem;
  }
  .txt-bx h3 {
    padding-bottom: 0.7rem;
  }
  .txt-col {
    gap: 24px;
  }
}

@media only screen and (max-width: 786px) {
  .txt-bx h3 {
    font-size: 18px;
  }
  .txt-bx p {
    font-size: 14px;
  }
  .txt-col {
    gap: 8px;
  }
}

.inputWrapper {
  position: relative;
  margin-bottom: 10px;
}

.view {
  display: block;
}

.passwordView {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(../../../assets/icons/no-view.svg) 0 0 no-repeat;
}

.passwordControl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(../../../assets/icons/view.svg) 0 0 no-repeat;
}

.my_input {
  width: 100%;
  height: 52px;
  background: #ffffff;
  padding: 18px;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  border: 1.5px solid #99a8fc;
  border-radius: 170px;
  color: #2a2e37;
}

.my_input:focus {
  outline: 1.5px solid #99a8fc;
}

/* .my_input:hover {
  outline: 1.5px solid #979797;
} */

.hidden {
  display: none;
}

.error_input {
  border: 1.5px solid #ff6471 !important;
}
input::placeholder {
  color: #e3e8fe;
}

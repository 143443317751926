.open-account-section {
  margin-top: 100px;
}

.client-content-container {
  padding-top: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.5rem;
}

.client-content-description {
  color: #2a2e37;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
}

.client-img-container {
  position: relative;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.client-content-img {
  width: 100%;
  max-width: 500px;
}

.physical-client-list {
  padding-bottom: 2rem;
}

.physical-client-detail {
  margin-block: 2rem;
}

/* FOR LARGE DEVICES */
@media screen and (min-width: 800px) {
  .open-account-section {
    margin-top: 100px;
  }
  .client-content-container {
    padding-top: 2rem;
    grid-template-columns: 1fr 1fr;
  }

  .client-img-container {
    align-self: center;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .physical-client-detail {
    margin-block: 3rem;
  }
}

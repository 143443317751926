:root {
  --light-gray: #eeefef;
}

.layout-container {
  /* background: url('/src/components/assets/back.svg') no-repeat, #F6F9FF;
    background-size: cover; */
  background-color: #f5f5f5;
}

.dashboard-main-container {
  margin-inline: 0.8rem;
}

.bank-transfer-main-container {
  display: flex;
  width: 100%;
  padding: 27px 7px;
  flex-direction: column;
  gap: 48px;
  border-radius: 16px;
  background: rgba(3, 4, 94, 0.05);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  transition: all 0.3s ease-in-out;
}

.dashboard-dim-container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 300px;
  border-radius: 16px;
  background: rgba(3, 4, 94, 0.03);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  text-align: start;
  margin-bottom: 2rem;
  padding-block: 1.5rem;
  padding-inline: 0.5rem;
}

@media (min-width: 500px) {
  .dashboard-main-container {
    margin-inline: 1.5rem;
  }
}

@media (min-width: 900px) {
  .dashboard-main-container {
    margin-inline: 2rem;
  }

  .bank-transfer-main-container {
    width: 60%;
  }
}

@media (min-width: 1400px) {
  .dashboard-main-container {
    margin-left: 290px;
  }
}

/* DASHBOARD TABLE -------------------------------------------------------------------------------------------------- */
.primary-table-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 300px;
  border-radius: 16px;
  background: rgba(3, 4, 94, 0.03);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  text-align: start;
  margin-bottom: 2rem;
  padding-block: 1.5rem;
}

table {
  position: relative;
  border: none;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

.table-title-content {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

table caption {
  font-size: var(--h2-font-size);
  font-weight: 700;
  padding: 1rem;
  text-align: left;
  position: relative;
  color: #03045e;
}

table tr {
  border: none;
  padding: 0.35em;
}

table th,
table td {
  text-align: left;
  padding: 1rem;
}

table td {
  display: table-cell;
  text-align: left;
}

table th,
table tr {
  border-bottom: 1px solid #fff;
}

table tr:last-child {
  border-bottom: none;
}

table th {
  font-size: var(--h4-font-size);
  letter-spacing: 0.1em;
  font-weight: 700;
}

@media screen and (max-width: 599px) {
  table {
    border: 0;
  }

  .table-title-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }

  table caption {
    font-size: 1.3em;
  }

  .td_img {
    text-align: center;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    display: block;
  }

  table td {
    display: block;
    font-size: var(--normal-font-size);
    text-align: right;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    margin-bottom: 0.3rem;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

.primary-button {
  font-size: var(--normal-font-size);
  padding: 12px 13px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1.5px solid var(--Main-Blue, #03045e);
  background: #fff;
  cursor: pointer;
  transition: all 0.3s;
}

.primary-button:hover {
  background: #03045e;
  color: white;
  transition: 0.3s all ease;
}

.secondary-button {
  display: flex;
  width: 165px;
  padding: 12px 13px;
  justify-content: center;
  font-size: var(--normal-font-size);
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1.5px solid var(--Main-Blue, #03045e);
  color: #03045e;
  background: #fff;
  cursor: pointer;
  font-weight: 700;
  transition: all 0.3s;
}

.secondary-button:hover {
  background: #03045e;
  color: var(--white-color);
}

@media screen and (min-width: 768px) {
  .secondary-button,
  .primary-button {
    padding: 15px 32px;
  }
}

.add-account-modal:hover {
  border: 1px solid transparent;
}

.main-card-text-container {
  flex: 1;
  display: flex;
  height: 80px;
  padding: 12px 24px;
  align-items: center;
  gap: 12px;
}

button span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  background: transparent;
  border: 1.5px solid var(--Main-Blue, #03045e);
  color: var(--Main-Blue, #03045e);
  border-radius: 50px;
  padding: 10px 15px;
  margin: 0.5rem;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
}

.pagination button:hover {
  background: #03045e;
  color: white;
}

.pagination button.active {
  background: #03045e;
  color: white;
}

.pagination button:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
  display: none;
}

.pagination-ellipsis {
  margin: 0 5px;
  color: var(--Main-Blue, #03045e);
}

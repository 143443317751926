.button {
  display: flex;
  align-items: center;
  gap: 13px;
  border: 1px solid var(--main-red);
  border-radius: 120px;
  width: fit-content;
  padding: 10px 16px;
  text-decoration: none;
  color: var(--main-red);
  font-size: 14px;
  transition: all 0.3s;
  cursor: pointer;
  background-color: transparent;
}

.button span {
  white-space: nowrap;
}

.white {
  background-color: transparent;
  border-color: white;
  color: white;
}

.arrow_padding {
  padding: 8px;
  padding-left: 12px;
  padding-right: 8px;
}

.full_width {
  width: 100%;
  justify-content: center;
}

.button:hover {
  background-color: var(--main-red);
  color: white;
}

.white:hover {
  background-color: white;
  color: var(--main-red);
}

svg {
  width: 21px;
  height: 21px;
}

/* for md devices */
@media (min-width: 768px) {
  .button {
    font-size: 20px;
    padding: 14px 32px;
    gap: 32px;
  }

  .arrow_padding {
    padding: 8px;
    padding-left: 32px;
  }

  .button img {
    max-width: 43px;
  }

  svg.arrow {
    width: 42px;
    height: 42px;
  }
}

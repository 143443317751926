.contact_form {
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', serif;
  gap: 24px;
}

/* left */
.contact_form .info {
  background: var(--gradient-background);
  color: white;
  padding: 1.5rem;
  border-radius: 16px;
  width: 100%;
}

.info h3 {
  margin-top: 32px;
  font-size: 20px;
  font-weight: 500;
}

.info h3:first-child {
  margin-top: 0;
}

.info p {
  margin-top: 12px;
  font-size: 16px;
  font-weight: 400;
}

.info p.gray {
  color: var(--light-blue);
}

.info p a {
  text-decoration: none;
  color: white;
}

/* right */
.contact_form .input_form {
  border-radius: 16px;
  background-color: white;
  color: var(--dark-blue);
  padding: 48px 24px;
  width: 100%;
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.08);
}

.input_block{
  display: flex;
  flex-direction: column;
  position: relative;
}

.input_block .lable{
  margin-bottom: 8px;
}

.input_block .input{
  margin-bottom: 10px;
  padding: 18px 24px;
  outline: none;
  border: 1px solid var(--light-blue);
  border-radius: 120px;
  font-size: 16px;
  color: var(--dark-blue);
}

.input_block .input::placeholder{
  color: #E3E8FE;
}

.input_block .input:hover::placeholder {
  color: var(--light-blue);
}

.input_block .input:focus::placeholder {
  color: transparent;
}

.input_block .input.filled {
  color: var(--main-red);
  border-color: var(--main-red);
}

.input_block textarea.input{
  border-radius: 37px;
  padding: 8px 24px;
}

.button {
  gap: 13px;
  border: 1px solid var(--main-red);
  border-radius: 120px;
  width: fit-content;
  padding: 18px;
  text-decoration: none;
  color: var(--main-red);
  font-size: 20px;
  background-color: white;
  width: 100%;
  margin-top: 32px;
  cursor: pointer;
  transition: all 0.3s;
}

.button:hover{
  background-color: var(--main-red);
  color: white;
}

.error{
  color: red;
  font-size: 12px;
  margin-top: 5px;
  display: block;
}

.form_btn{
  margin-top: 32px;
}

/* for md devices */
@media (min-width: 768px) {
  .contact_form {
    flex-direction: row;
  }

  .contact_form .info {
    max-width: calc(50% - 12px);
    padding-block: 5rem;
    padding-inline: 3rem;
  }

  .info h3{
    font-size: 24px;
  }

  .info p {
    font-size: 18px;
  }

  .contact_form .input_form {
    max-width: calc(50% - 12px);
    padding-block: 5rem;
    padding-inline: 4rem;
  }
}

/* for lg devices */
@media (min-width: 1024px) {
}

.contact .contact-input-block {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.my_input {
  width: 100%;
  height: 52px;
  background: #ffffff;
  padding: 18px;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  border: 1.5px solid #eeefef;
  border-radius: 8px;
  color: #2a2e37;
}

.my_input:focus {
  outline: 1.5px solid #979797;
}

.my_input:hover {
  outline: 1.5px solid #979797;
}

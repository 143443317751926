.other_page {
  margin-top: 32px;
}

/* Advantages and benefits start */
.benefits {
  margin-top: 100px;
  margin-bottom: 100px;
  color: var(--dark-blue);
}

.head .left h2 {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
}

.head .left p {
  font-size: 16px;
  font-weight: 400;
  margin-top: 48px;
}

.head .right {
  display: none;
}

.cards {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
}

.cards img {
  object-fit: cover;
  border-radius: 16px;
  max-width: 100%;
  width: 100%;
}

.cards .right {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cards .right .top {
  background: var(--gradient-background);
  border-radius: 16px;
  color: white;
  padding: 48px 24px;
}

.cards h3 {
  font-size: 16px;
  font-weight: 400;
}

.cards p {
  font-size: 16px;
  font-weight: 400;
  margin-top: 24px;
}

.cards .right .bottom {
  width: 100%;
  background-color: white;
  border-radius: 16px;
  padding: 48px 24px;
  border: 1px solid var(--light-blue);
}

.mobile_btn {
  margin-top: 24px;
}

/* for md devices */
@media (min-width: 768px) {
  .benefits {
    margin-top: 200px;
    margin-bottom: 200px;
  }

  .head {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }

  .head .left {
    width: calc(50% - 12px);
  }

  .head .left h2 {
    font-size: 50px;
  }
  .head .left p {
    font-size: 24px;
    margin-top: 80px;
  }

  .head .right {
    display: block;
  }

  .cards {
    flex-direction: row;
    margin-top: 24px;
  }

  .cards img {
    width: calc(50% - 12px);
  }

  .cards .right {
    width: calc(50% - 12px);
    gap: 24px;
  }

  .cards h3 {
    font-size: 24px;
  }

  .cards p {
    font-size: 20px;
  }

  .cards .right .bottom {
    flex-direction: row;
    gap: 24px;
    flex-grow: 1;
  }

  .mobile_btn {
    display: none;
  }
}
/* Advantages and benefits end */

/* services section start */
.services h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--dark-blue);
  width: 100%;
}

.cards_row1 {
  margin-top: 48px;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 8px;
}

.cards_row2 {
  margin-top: 8px;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 8px;
}

.card {
  color: var(--dark-blue);
  border: 1px solid var(--light-blue);
  padding: 24px;
  background-color: white;
  border-radius: 16px;
}

.card h3 {
  font-size: 20px;
  font-weight: 500;
  margin-top: 24px;
}

.card p {
  font-size: 16px;
  font-weight: 400;
  margin-top: 24px;
}

/* for md devices */
@media (min-width: 768px) {
  .services h2 {
    font-size: 50px;
    width: 50%;
  }

  .cards_row1 {
    margin-top: 80px;
    gap: 24px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .cards_row2 {
    margin-top: 24px;
    gap: 24px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .card {
    padding: 48px 24px;
  }

  .card h3 {
    font-size: 24px;
  }

  .card p {
    font-size: 18px;
  }
}
/* services section end */

/* note start */
.note {
  margin-top: 100px;
  padding: 48px 24px;
  background-color: white;
  border-radius: 16px;
  font-style: italic;
  color: var(--dark-blue);
  font-size: 24px;
  text-align: center;
  box-shadow: -4px 0px 20px 0px rgba(0, 0, 0, 0.08);
}

.note span {
  color: var(--light-blue);
}

/* for md devices */
@media (min-width: 768px) {
  .note {
    margin-top: 200px;
    padding: 100px 145px;
    font-size: 50px;
  }
}
/* note end */

/* Precious Metals Operations section start */
.gold_cards {
  margin-top: 8px;
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.gold_cards .card {
  background-color: white;
  border-radius: 16px;
  padding: 48px 24px;
  color: var(--dark-blue);
}

.gold_cards .card h4 {
  font-size: 16px;
  font-weight: 400;
}

.gold_cards .card p {
  margin-top: 24px;
  font-size: 16px;
  font-weight: 400;
}

/* for md devices */
@media (min-width: 768px) {
  .gold_cards {
    margin-top: 24px;
    gap: 24px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .gold_cards .card h4 {
    font-size: 24px;
  }

  .gold_cards .card p {
    font-size: 18px;
  }
}
/* Precious Metals Operations section end */

/* forex trading section start */
.forex {
  margin-top: 24px;
  color: var(--dark-blue);
  margin-bottom: 100px;
}

.forex h3 {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
}

.forex .offers {
  margin-top: 24px;
}

.forex .offers .offer {
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--light-blue);
}

.forex .offers .offer .number {
  width: 25%;
  font-size: 14px;
}

.forex .offers .offer .line {
  width: 75%;
  font-size: 14px;
}

/* for md devices */
@media (min-width: 768px) {
  .forex {
    margin-top: 78px;
    margin-bottom: 200px;
  }

  .forex h3 {
    width: 50%;
    font-size: 24px;
  }

  .forex .offers {
    margin-top: 80px;
  }

  .forex .offers .offer .number {
    width: 50%;
    font-size: 24px;
  }

  .forex .offers .offer .line {
    width: 50%;
    font-size: 24px;
  }
}
/* forex trading section end */

/* sections padding */
.padding_section {
  padding-top: 100px;
}

/* for md devices */
@media (min-width: 768px) {
  .padding_section {
    padding-top: 200px;
  }
}

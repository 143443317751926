.currency-conversion-container {
    display: grid;
    text-align: left;
    row-gap: 1.5rem;
    grid-template-columns:1fr;
    flex-direction: column;
    column-gap: 1rem;
}

.currency-conversion-content {
    position: relative;
    align-items: center;
    border-radius: 16px;
    padding-inline: 1rem;
    padding-top: 1rem;
    background: rgba(3, 4, 94, 0.03);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);
    text-align: start;
    margin-bottom: 22rem;
}

.currency-conversion-rate {
    display: flex;
    column-gap: 1rem;
    flex-wrap: wrap;
}

.currency-conversion-red-span {
    color: red;
}

@media screen and (min-width: 768px) {
    .currency-conversion-rate .transfer-form-box {
        max-width: 170px;
    }
}

@media screen and (min-width: 1000px) {
    .currency-conversion-container {
        flex-direction: row;
        grid-template-columns: 5fr 2fr;
    }
}
















/* CustomDropdown.css */
.custom-dropdown {
    position: relative;
    width: 100%;
}

.custom-dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 8px;
    border: transparent;
    border-radius: 7px;
    background-color: #fff;
    cursor: pointer;
}

.dropdown-arrow-icon {
    width: 12px;
    height: 12px;
}

.custom-dropdown-list {
    position: absolute;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1;
}

.custom-dropdown-list-item {
    padding: 8px;
    cursor: pointer;
}

.custom-dropdown-list-item:hover,
.custom-dropdown-list-item.selected {
    background-color: #f0f0f0;
}

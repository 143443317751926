.modalWrapper {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999999;
}

.contentWrapper {
  width: 560px;
  height: 560px;
  background: #ffffff;
  position: absolute;
  overflow: hidden;
  z-index: 9999999;
  border-radius: 16px;
  border: 1px solid #03045e;
}

.contentWrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 32px;
  background-color: #03045e;
}

.shortContent {
  padding: 52px;
}

.content {
  padding: 112px 40px;
  box-sizing: border-box;
}

.confirmModal::before {
  background-color: #ffbb54;
}

.error::before {
  background-color: #ff6471;
}

.contact::before {
  background-color: transparent;
}

.modalSection {
  justify-content: center;
  display: flex;
  margin-top: 30px;
  text-align: center;
}

/* ------------------------------------------------------------------------------------------------------------------ */
.addAccountModal,
.addCryptoModal {
  border: 1px solid var(--gray-color);
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}

.addAccountButtons {
  padding-top: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: stretch;
  gap: 1rem;
}

@media only screen and (max-width: 768px) {
  .contentWrapper {
    width: 100%;
    height: 70%;
  }

  .content {
    padding: 80px 40px;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 500px) {
  .contentWrapper {
    width: 100%;
    height: 500px;
  }

  .content {
    padding: 80px 40px;
    box-sizing: border-box;
  }
}

:root {
    --header-height: 50px;
    --sidebar-width: 50px;
    --gray-color: #979797;
    --title-color: #03045E;
    --white-color: #FFF;
    --Gray: #979797;
    --Primary-background: #F6F9FF;
    --Background: #F6F9FF;
    --Main-Blue: #03045E;
    --Black: #2A2E37;
}

.password_change-container {
    text-align: left;
    row-gap: 1.5rem;
    display: grid;
    grid-template-columns:1fr;
    flex-direction: column;
    column-gap: 2rem;
}

.password_change-content {
    border-radius: 12px;
    background: rgba(3, 4, 94, 0.05);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
}

.change_password-form {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.password-input-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.password-input-wrapper .input_container {
    position: relative;
}

.change_password-input {
    outline: none;
    border: none;
    height: 20px;
    padding: 1rem;
    border-radius: 8px;
    background: var(--white-color);
}

.change_password-input:focus {
    border: 1.5px solid var(--Gray, #979797);
}

.change_password-label {
    color: var(--gray-color);
}

.icon_1 {
    color: red;
    font-size: 16px;
    font-weight: 600;
}

.toggle-password-button {
    position: absolute;
    right: 1px;
    top: 25px;
    transform: translateY(-50% );
    background: none;
    border: none;
    outline: none;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.toggle-password-icon {
    width: 100%;
    
}

.change_password-button {
    margin-left: auto;
}

/* -------------------------- */
.bottom_content {
    display: flex;
    flex-direction: column;
}

.button_two {
    margin-left: auto;
    margin-top: 10px;
}

.table_two {
    border-bottom: 1.5px solid var(--Primary-background) ;
}

.button_three {
    outline: none;
    border: none;
    color: red;
    background-color: transparent;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}

/* -------------------------------------------------------- */
.password_change-confirm {
    display: flex;
    padding: 60px 11px;
    height: max-content;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    backdrop-filter: blur(5px);
    border-radius: 12px;
    background: rgba(3, 4, 94, 0.05);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    row-gap: 1.5rem;
}

.password_change-title {
    color: var(--Main-Blue);
    text-align: center;
    font-size: var(--h4-font-size);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.password_change-content .password_change-title {
    text-align: left;
}

/* FOR LARGE DEVICES ----------------------------------------------------- */
@media (min-width: 768px) {
    .password_change-content {
        padding: 2rem;
    }

}

@media screen and (min-width: 1000px) {
    .password_change-container {
        flex-direction: row;
        grid-template-columns: 5fr 2fr;
    }

    .password-input-wrapper {
        width: 50%;
    }
}


@media (max-width: 768px) {
    .password_change-section {
        margin-bottom: 2rem;
        width: 100%;
    }
}


.table-container-one {
    display: flex;
    flex-direction: column;
}

.table-header-one .table-cell-one {
    color: var(--Black);
}

.table-header-one, .table-row-one {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: var(--gray-color);
}

.table-row-one:nth-child(2) {
    margin-top: .5rem;
}

.table-cell-one {
    flex: 1;
    text-align: left;
}

.table-header-one .table-cell-one {
    font-weight: bold;
}

.table-row-one .table-cell-one:last-child, .table-header-one .table-cell-one:last-child {
    border-right: none;
}

@media (max-width: 768px) {
    .table-header-one {
        display: none;
    }

    .table-header-one, .table-row-one {
        flex-direction: column;
        border-right: none;
    }

    .table-cell-one {
        text-align: right;
        border-right: none;
    }

    .table-row-one .table-cell-one::before {
        content: attr(data-label);
        font-weight: bold;
        display: inline-block;
        text-align: left;
        color: black;
        width: 100%;
        margin-block: .5rem;
    }
}
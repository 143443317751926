
.quest_block {
    position: absolute;
    top: 40px;
    right: 60px;
}

.sign_in_content {
    max-width: 430px;
    position: relative;
}

.sign_in_content img {
    position: relative;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    margin-bottom: 24px;
}

.back_arrow_icon {
    position: absolute;
    top: 26px;
    left: 26px;
    z-index: 2;
}

.title {
    color: #000;
    text-align: center;
    font-family: Mulish, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.input_label {
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    margin-bottom: 8px;
}

.span_password {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    color: #979797;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
}

.span_password:hover {
    color: #EF233C;
    cursor: pointer;
}

.error {
    font-family: 'Mulish', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-top: 4px;
}

.btn_block {
    display: flex;
    justify-content: center;
    margin-top: 38px;
}

/* .my_btn:hover {
    background: #2A2CD9;
    transition: .3s all ease;
} */





.error_block {
    height: 52px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
    background: #FFE8EA;
    border-radius: 8px;
    color: #FF6471;
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}

@media only screen and (max-width: 768px) {


    .logo_block {
        position: relative;
        max-width: 250px;
        max-height: 383px;
        align-items: center;
        justify-content: center;
        display: flex;

    }

    .sign_in_content {
        width: 100%;
    }

    .my_input {
        font-family: 'Mulish', serif;
        width: 100%;
        height: 40px;
        background: #FFFFFF;
        padding: 14px 12px;
        box-sizing: border-box;
        outline: none;
        border: none;
        margin-top: 20px;
    }


    .logo_block {
        position: absolute;
        top: 20px;
        left: 16px;
    }
}

.dropdown {
    position: relative;
    width: 100%;
}

.dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: var(--white-color);
    padding: 1rem;
    font-size: var(--normal-font-size);
    border-radius: 8px;
    border: 1px solid var(--white-color);
    height: 100%;
    width: 100%;
    transition: border 0.3s;
}

.dropdown-header:hover {
    border: 1px solid var(--gray-color);
}

.dropdown-arrow {
    margin-left: 10px;
}

.dropdown-arrow-icon {
    width: 12px;
    height: 12px;
    user-select: none;
}

.dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-top: 2px;
}

.dropdown-list-item {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.dropdown-list-item:hover {
    background-color: #f0f0f0;
}

.dropdown-list-item.selected {
    background-color: #03045E;
    color: var(--white-color);
}

.modal-wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999999;
}

.content_wrapper {
  width: 312px;
  height: 356px;
  background: #ffffff;
  position: absolute;
  overflow: hidden;
  z-index: 9999999;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 45px;
  text-align: center;
}

.content_wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 32px;
}

.content_wrapper.confirm::before {
  background: var(--gradient-background);
}

.content_wrapper.error::before {
  background-color: var(--main-red);
}

.popup_title {
  font-size: 24px;
  font-weight: 400;
  margin-top: 112px;
}

.popup_desc {
  font-size: 14px;
  font-weight: 400;
  margin-top: 24px;
}

.content_wrapper.confirm .popup_title,
.content_wrapper.confirm .popup_desc {
  color: var(--dark-blue);
}

.content_wrapper.error .popup_title,
.content_wrapper.error .popup_desc {
  color: var(--main-red);
}

.modal-wrapper .btn {
  margin-top: 48px;
}

/* for md devices */
@media (min-width: 1024px) {
  .content_wrapper {
    width: 600px;
    height: 356px;
  }
}

.hero h1 {
  font-size: 32px;
  font-weight: 700;
  color: var(--dark-blue);
  margin-bottom: 16px;
  text-transform: uppercase;
  max-width: 23ch;
}

.banner {
  position: relative;
  width: 100%;
  height: 700px;
}

.banner .bg_img {
  border-radius: 16px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.banner .over_content_wrapper {
  width: 65%;
  position: absolute;
  bottom: 0;
  background-color: #f5f5f5;
}

.banner .over_content_wrapper.left_align {
  left: 0;
  padding: 8px 8px 0 0;
  border-top-right-radius: 24px;
}

.banner .over_content_wrapper.right_align {
  border-top-left-radius: 24px;
  right: 0;
  padding: 8px 0 0 8px;
}

.over_content_wrapper .over_content {
  padding: 16px;
  border-radius: 16px;
  position: relative;
}

.over_content.right_align::before {
  content: '';
  position: absolute;
  width: 45px;
  height: 45px;
  bottom: 0px;
  left: -53px;
  box-shadow: 6px 6px 0 0 #f5f5f5;
  border-radius: 0 0 16px 0;
}

.over_content.right_align::after {
  content: '';
  position: absolute;
  width: 45px;
  height: 45px;
  top: -53px;
  right: 0px;
  box-shadow: 6px 6px 0 0 #f5f5f5;
  border-radius: 0 0 16px 0;
}

.over_content.left_align::before {
  content: '';
  position: absolute;
  width: 45px;
  height: 45px;
  left: 0px;
  top: -53px;
  box-shadow: -6px 6px 0 0 #f5f5f5;
  border-radius: 0 0 0 16px;
}

.over_content.left_align::after {
  content: '';
  position: absolute;
  width: 45px;
  height: 45px;
  bottom: 0px;
  right: -53px;
  box-shadow: -6px 6px 0 0 #f5f5f5;
  border-radius: 0 0 0 16px;
}

.gradient {
  background: var(--gradient-background);
  color: white;
}

.white {
  background: white;
  color: var(--dark-blue);
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.08);
}

.over_content p {
  font-size: 14px;
}

.over_content .btn {
  margin-top: 12px;
}

/* for md devices */
@media (min-width: 768px) {
  .hero h1 {
    font-size: 65px;
  }

  .banner .over_content_wrapper {
    width: 50%;
  }

  .banner .over_content_wrapper.left_align {
    padding: 8px 8px 0 0;
    border-top-right-radius: 24px;
    border-top-left-radius: 0;
  }

  .banner .over_content_wrapper.right_align {
    padding: 8px 0 0 8px;
    border-top-right-radius: 0;
    border-top-left-radius: 24px;
  }

  .over_content_wrapper .over_content {
    padding: 48px;
  }

  .over_content p {
    font-size: 18px;
  }
  .over_content .btn {
    margin-top: 24px;
  }
}

.currency-transaction-filter-form {
  padding-inline: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.currency-transaction-form-group {
  display: flex;
  flex-direction: column;
  column-gap: 1.5rem;
  width: 100%;
}

.currency-filter-dropdown {
  width: 100%;
}

.currency-filter-form-box {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  max-width: 600px;
  width: 100%;
  margin-bottom: 1.3rem;
}

.currency-transaction-form-checkbox {
  margin-right: 0.3rem;
}

.currency-filter-input {
  outline: none;
  padding: 1rem;
  height: max-content;
  font-size: var(--normal-font-size);
  border-radius: 8px;
  background: var(--white-color);
  border: 1px solid var(--white-color);
}

.currency-filter-input:focus {
  border: 1px solid var(--gray-color);
}

.currency-transaction-button {
  display: flex;
  justify-content: end;
}

/* currency transaction info panel */
.transaction-info-panel {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.transaction-info-panel_label {
  margin-inline: 0.5rem;
}

.transaction-info-panel_value {
  font-weight: 600;
  margin-right: 0.5rem;
}

/* currency transaction table */
.currency-transaction-table {
  position: relative;
  border: none;
  border-collapse: collapse;
  width: 100%;
  table-layout: auto;
}

.currency-transaction-table .currency-transaction-caption {
  font-size: var(--h2-font-size);
  font-weight: 700;
  padding: 1rem;
  text-align: left;
  color: #03045e;
}

.currency-transaction-header .header-row {
  border-bottom: 1px solid #ddd;
}

.currency-transaction-header .header-cell {
  font-size: var(--normal-font-size);
  font-weight: 700;
  text-align: left;
  padding: 1rem;
  letter-spacing: 0.1em;
  word-wrap: break-word;
}

.currency-transaction-body .body-row {
  border-bottom: none;
}

.currency-transaction-body .body-row:hover {
  background-color: hsla(239, 94%, 19%, 0.05);
}

.currency-transaction-body .body-cell {
  text-align: left;
  padding: 1rem;
  font-size: calc(var(--normal-font-size) - 1px);
}

.currency-transaction-body .body-row:last-child {
  border-bottom: none;
}

/* transaction hover details ---- */
.transaction-hover-container {
  min-height: 220px;
}

.transaction-hover-details {
  margin-top: 1rem;
  padding: 1rem 2rem;
  font-size: var(--normal-font-size);
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.transaction-hover-details.visible {
  opacity: 1;
  transform: translateY(0);
}

.transaction-hover-details .hover-detail {
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 1rem;
  text-align: right;
  border-top: 2px solid hsla(239, 94%, 19%, 0.05);
  padding-block: 1rem;
}

.transaction-hover-details .detail-label {
  font-weight: bold;
}

.transaction-hover-details .detail-value {
  width: 150px;
}

.currency-statement-download {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  gap: 0.5rem;
}

.currency-download-label {
  font-weight: bold;
}

.currency-statement-download .download-format {
  color: var(--dark-blue);
  font-weight: bolder;
}

/* breakpoints ---- */

@media screen and (max-width: 1096px) {
  .currency-transaction-table .currency-transaction-header {
    display: none;
  }

  .currency-transaction-table .currency-transaction-body .body-row {
    display: block;
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--white-color);
  }

  .currency-transaction-table .currency-transaction-body .body-cell {
    display: block;
    font-size: var(--normal-font-size);
    text-align: right;
  }

  .currency-transaction-table .currency-transaction-body .body-cell::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    width: 300px;
    text-align: left;
  }
}

@media screen and (min-width: 768px) {
  .currency-transaction-filter-form {
    padding-inline: 1.5rem;
  }
}

@media screen and (min-width: 900px) {
  .currency-transaction-filter-form {
    width: 900px;
  }
}

@media (min-width: 1024px) {
  .account-statement-filter-container {
    width: max-content;
  }

  .currency-filter-dropdown {
    max-width: 400px;
  }

  .currency-transaction-form-group {
    flex-direction: row;
  }
}

.about_page {
  margin-bottom: 100px;
}

.hero {
  margin-top: 16px;
}

.innovative_solutions {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.left {
  width: 100%;
}

.left h2 {
  color: var(--dark-blue);
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
}

.left .desc {
  color: var(--dark-blue);
  font-size: 16px;
  font-weight: 400;
  margin-top: 48px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.left .btn {
  display: none;
}

.right {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 8px;
  margin: auto;
}

.right .card {
  padding: 24px;
  color: var(--dark-blue);
  font-size: 16px;
  border-radius: 16px;
  overflow: hidden;
  background-color: white;
  font-weight: 400;
}

.right .white_card{
  border: 1px solid var(--light-blue);
}

.right .card h4 {
  font-size: 16px;
  font-weight: 400;
}

.right .card p {
  margin-top: 24px;
  font-size: 16;
}

.right .gradient_bg {
  color: white;
  background: var(--gradient-background);
}

.right .card_img {
  width: 100%;
  object-fit: cover;
  display: block;
  border-radius: 16px;
  height: 358px;
}

.right .btn {
  display: block;
  margin-top: 24px;
}

.quote {
  margin-top: 100px;
}

.quote p {
  background-color: white;
  color: var(--dark-blue);
  font-size: 24px;
  font-weight: 500;
  font-style: italic;
  text-align: center;
  padding: 48px 24px;
  border-radius: 16px;
  box-shadow: -4px 0px 20px 0px rgba(0, 0, 0, 0.08);
}

.quote span {
  color: var(--main-blue);
}

/* for md devices */
@media (min-width: 768px) {
  .left {
    position: relative;
  }
}

/* for md devices */
@media (min-width: 1024px) {
  .about_page {
    margin-bottom: 200px;
  }

  .hero {
    margin-top: 32px;
  }

  .innovative_solutions {
    margin-top: 200px;
    flex-direction: row;
    gap: 24px;
  }

  .left {
    width: calc(50% - 12px);
    position: relative;
  }
  .right{
    width: calc(50% - 12px);
  }

  .left .desc {
    font-size: 18px;
    margin-top: 80px;
    margin-bottom: 130px;
    gap: 24px;
  }

  .left .btn {
    display: block;
    position: absolute;
    bottom: 0;
  }

  .right .btn {
    display: none;
  }

  .quote {
    margin-top: 200px;
  }

  .quote p {
    font-size: 50px;
    padding: 100px 145px;
  }

  .left h2 {
    font-size: 45px;
  }
}

@media (min-width: 1280px) {
  .left h2 {
    font-size: 50px;
  }

  .right {
    width: calc(50% - 12px);
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(10, 1fr);
    gap: 24px;
  }

  /* cards layout */
  .card_img {
    grid-row: 1/8;
    grid-column: 1;
  }

  .card_1 {
    grid-row: 1/6;
    grid-column: 2;
  }

  .card_2 {
    grid-row: 8/11;
    grid-column: 1;
  }

  .card_3 {
    grid-row: 6/11;
    grid-column: 2;
  }

  .right .card {
    padding: 48px 24px;
  }

  .right .card h4 {
    font-size: 24px;
  }

  .right .card p {
    font-size: 18px;
  }

  .right .card_img {
    height: 100%;
  }
}

.payment_cards_page {
  margin-top: 16px;
  margin-bottom: 100px;
}

.cards {
  margin-top: 100px;
}

.cards .card {
  padding: 48px 24px;
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  color: var(--dark-blue);
  background-color: white;
}

.cards .card:first-child {
  margin-bottom: 12px;
}

.card_info {
  display: flex;
  flex-direction: column;
}

.card_info .info h3 {
  font-size: 20px;
  text-transform: uppercase;
}

.card_info .info .features {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.card_info .info .features p {
  font-size: 18px;
}

.card_info .card_img_desktop {
  display: none;
}

.card .card_img_mobile {
  width: 100%;
  margin-top: 48px;
}

.about_card {
  margin-top: 48px;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 8px;
}

.about_card div p {
  font-size: 18px;
}

.about_card div p:first-child {
  color: var(--light-blue);
  margin-bottom: 4px;
}

/* for md devices */
@media (min-width: 768px) {
  .payment_cards_page {
    margin-top: 16px;
    margin-bottom: 200px;
  }

  .cards {
    margin-top: 200px;
  }

  .cards .card {
    padding: 64px 48px;
  }

  .cards .card:first-child {
    margin-bottom: 48px;
  }

  .card_info {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .card_info .info h3 {
    font-size: 50px;
  }

  .card_info .info .features {
    margin-top: 72px;
  }

  .card_info .info .features p {
    font-size: 24px;
  }

  .card_info .card_img_desktop {
    display: block;
    width: 40%;
  }

  .card .card_img_mobile {
    display: none;
  }

  .about_card {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    margin-top: 56px;
    gap: 8px;
  }
}

.crypto_currency_page {
  margin-top: 16px;
  margin-bottom: 100px;
}

.crypto_payment {
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
}

.crypto_payment h2 {
  width: 100%;
  font-size: 32px;
  font-weight: 700;
  color: var(--dark-blue);
  text-transform: uppercase;
}

.crypto_payment .btn {
  display: none;
}

.payment_services {
  margin-top: 48px;
}

.payment_services .title {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: var(--dark-blue);
}

.payment_services .flex {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.payment_services .flex .left {
  width: 100%;
  border-radius: 16px;
  object-fit: cover;
  min-height: 350px;
}

.payment_services .flex .right {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.right .line {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.right .line div {
  width: 100%;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.right .line div p {
  font-size: 16px;
}

.right .line .gradient_card {
  background: var(--gradient-background);
  color: white;
}

.right .line .white_card {
  background-color: white;
  color: var(--dark-blue);
  border: 1px solid var(--light-blue);
}

/* for md devices */
@media (min-width: 768px) {
  .crypto_currency_page {
    margin-top: 32px;
    margin-bottom: 200px;
  }

  .crypto_payment {
    margin-top: 200px;
  }

  .crypto_payment h2 {
    font-size: 50px;
    max-width: 70%;
  }

  .crypto_payment .btn {
    display: block;
  }

  .payment_services .title {
    width: 50%;
    font-size: 24px;
  }

  .payment_services .flex {
    margin-top: 48px;
    flex-direction: row;
    gap: 24px;
  }

  .payment_services .flex .left {
    width: calc(50% - 12px);
  }

  .payment_services .flex .right {
    width: calc(50% - 12px);
    gap: 24px;
  }

  .right .line {
    flex-direction: row;
    gap: 24px;
  }

  .right .line div {
    width: calc(50% - 12px);
    padding: 48px 24px;
  }

  .right .line div p {
    font-size: 20px;
  }
}

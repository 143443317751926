/* Mobile First (max-width: 1199px) */
.header-container {
  border-bottom: 1px solid #eaeaea;
  position: relative;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
}

.header-logo-img {
  height: 90px;
  width: 90px;
  display: block;
}

.nav {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding: 0px 16px;
}

@media screen and (max-width: 1280px) {
  .nav-list {
    display: flex;
    position: absolute;
    top: -1000px;
    right: 0;
    padding-block: 4rem 1rem;
    padding-inline: 1.5rem;
    list-style: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: max-content;
    row-gap: 40px;
    z-index: 1000;
    border-radius: 16px;
    background-color: #ffffff;
  }
}

.nav-list.show {
  top: calc(100% - 15px);
}

.toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  z-index: 2000;
}

.menu-toggle {
  color: var(--dark-blue);
  outline: none;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  cursor: pointer;
  z-index: 1000;
}

.nav-link {
  color: var(--dark-blue);
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  width: max-content;
  transition: all 0.3s;
}

.nav-link.min-content {
  width: min-content;
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 160px;
  transition: 0.3s all;
}

.nav-link:hover {
  color: #d90429;
}

.active-nav-item .nav-link {
  color: #d90429;
}

/* HEADER DROPDOWN ----------------------------------------------------------------------------- */
.dropdown {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.header-arrow {
  width: var(--normal-font-size);
  height: var(--normal-font-size);
  /* margin-left: .5rem; */
}

.arrow-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.dropdown-content {
  top: 0;
  padding: 0.5rem;
  flex-direction: column;
  text-align: start;
  z-index: 1;
  border: 1px solid #eeefef;
  width: max-content;
  background-color: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  margin-block: 0.5rem;
}

.dropdown-content .dropdown-nav-link {
  display: block;
  color: var(--dark-blue);
  padding: 7px 16px;
  text-decoration: none;
  font-family: Mulish, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}

.dropdown-nav-link:hover {
  background-color: #e0e0e0;
  border-radius: 6px;
}

.header_link:hover {
  color: #979797 !important;
}

.header-button-container {
  position: relative;
  margin-left: auto; /* Ensure button aligns on the right with logo and nav-links */
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

/* BREAK POINTS ----------------------------------------------------------------------------------- */
@media (min-width: 1280px) {
  .nav {
    flex-direction: row;
    justify-content: space-between;
    padding: 24px;
    text-align: left;
  }

  .nav-item {
    height: 100%;
  }

  .header-logo-img {
    width: 100px;
    height: 75px;
  }

  .nav-list {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    border-radius: 16px;
  }

  .nav-item {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s all;
  }

  .toggle-container {
    display: none;
  }

  .header-button-container {
    width: initial;
  }

  .dropdown {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    transition: 0.3s all;
  }

  .dropdown-content {
    position: absolute;
    top: 5rem;
    margin-block: 0;
  }
  .dropdown-content .dropdown-nav-link {
    text-align: left;
  }
}

/* @media (min-width: 1300px) {
  .nav-list {
    column-gap: 1.5rem;
  }
} */

/* language dropdown */
.language-container {
  position: relative;
}

.language-lable {
  text-transform: uppercase;
  color: var(--dark-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.language-dropdown {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #eeefef;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  z-index: 99;
}

.language-dropdown p {
  padding: 7px 16px;
  color: var(--dark-blue);
  cursor: pointer;
}

.language-dropdown p:hover {
  background-color: #e0e0e0;
}

.component h2,
.component h1 {
  text-transform: uppercase;
  font-size: 32px;
  font-weight: 700;
  color: var(--dark-blue);
  max-width: 24ch;
}

.flex {
  display: flex;
  gap: 8px;
  margin-top: 48px;
  flex-direction: column;
}

.flex.hero_padding {
  margin-top: 16px;
}

.reverse {
  flex-direction: column-reverse;
}

.left {
  width: 100%;
  border-radius: 16px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.08);
}

.bg_gradient {
  background: var(--gradient-background);
  color: white;
}

.bg_white {
  background-color: white;
  color: var(--dark-blue);
}

.left p {
  font-size: 16px;
  font-weight: 400;
}

.btn {
  margin-top: 24px;
}

.btn.mt {
  margin-top: 24px;
}

.right {
  width: 100%;
  border-radius: 16px;
  object-fit: cover;
  min-height: 315px;
}

/* for md devices */
@media (min-width: 768px) {
  .component h2 {
    font-size: 50px;
  }

  .component h1 {
    font-size: 65px;
  }

  .flex {
    display: flex;
    gap: 24px;
    margin-top: 80px;
    flex-direction: row;
  }

  .flex.hero_padding {
    margin-top: 16px;
  }

  .reverse {
    flex-direction: row-reverse;
  }

  .left {
    width: calc(50% - 12px);
    padding: 48px;
  }

  .left p {
    font-size: 24px;
  }

  .btn.mt {
    margin-top: 150px;
  }

  .right {
    width: calc(50% - 12px);
    min-height: 495px;
  }
}

/* payment methods */
.scroll_container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  margin-top: 24px;
}

.scroll_content {
  display: flex;
  animation: scroll 10s linear infinite;
  will-change: transform;
  min-width: fit-content;
}

.scroll_content .icon {
  width: 100px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  margin-right: 16px;
  flex-shrink: 0;
}

.scroll_content .icon img {
  max-width: 80%;
  max-height: 80%;
}

/* for md devices */
@media (min-width: 768px) {
  .scroll_container {
    margin-top: 80px;
  }

  .scroll_content .icon {
    width: 160px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-right: 40px;
  }
}

/* animation */
@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(calc(-50%));
  }
}
